/* eslint-disable */
const NazioniIndirizzo = Object.freeze({
  "IT": {nome: "Italia", lunghezza: [11], eu: true},
  "AT": {nome: "Austria", lunghezza: [9], eu: true},
  "BE": {nome: "Belgio", lunghezza: [9], eu: true},
  "BG": {nome: "Bulgaria", lunghezza: [9, 10], eu: true},
  "CY": {nome: "Cipro", lunghezza: [9], eu: true},
  "DK": {nome: "Danimarca", lunghezza: [8], eu: true},
  "EE": {nome: "Estonia", lunghezza: [9], eu: true},
  "DE": {nome: "Germania", lunghezza: [9], eu: true},
  "EL": {nome: "Grecia", lunghezza: [9], eu: true},
  "FI": {nome: "Finlandia", lunghezza: [8], eu: true},
  "FR": {nome: "Francia", lunghezza: [11], eu: true},
  "GB": {nome: "Regno Unito", lunghezza: [5, 9, 12], eu: true},
  "IE": {nome: "Irlanda", lunghezza: [8], eu: true},
  "LV": {nome: "Lettonia", lunghezza: [11], eu: true},
  "LT": {nome: "Lituania", lunghezza: [9, 12], eu: true},
  "LU": {nome: "Lussemburgo", lunghezza: [8], eu: true},
  "MT": {nome: "Malta", lunghezza: [8], eu: true},
  "NL": {nome: "Paesi Bassi", lunghezza: [12], eu: true},
  "PL": {nome: "Polonia", lunghezza: [10], eu: true},
  "PT": {nome: "Portogallo", lunghezza: [9], eu: true},
  "CZ": {nome: "Repubblica Ceca", lunghezza: [8, 9, 10], eu: true},
  "SK": {nome: "Repubblica Slovacca", lunghezza: [9, 10], eu: true},
  "RO": {nome: "Romania", lunghezza: [10], eu: true},
  "SI": {nome: "Slovenia", lunghezza: [8], eu: true},
  "ES": {nome: "Spagna", lunghezza: [9], eu: true},
  "SE": {nome: "Svezia", lunghezza: [12], eu: true},
  "HU": {nome: "Ungheria", lunghezza: [8], eu: true},
  "MK": {nome: "Macedonia", eu: true},
  "AF": {nome: "Afghanistan"},
  "AL": {nome: "Albania"},
  "DZ": {nome: "Algeria"},
  "AD": {nome: "Andorra"},
  "AO": {nome: "Angola"},
  "AI": {nome: "Anguilla"},
  "AQ": {nome: "Antartide"},
  "AG": {nome: "Antigua e Barbuda"},
  "AN": {nome: "Antille Olandesi"},
  "SA": {nome: "Arabia Saudita"},
  "AR": {nome: "Argentina"},
  "AM": {nome: "Armenia"},
  "AW": {nome: "Aruba"},
  "AU": {nome: "Australia"},
  "AZ": {nome: "Azerbaijan"},
  "BS": {nome: "Bahamas"},
  "BH": {nome: "Bahrain"},
  "BD": {nome: "Bangladesh"},
  "BB": {nome: "Barbados"},
  "BZ": {nome: "Belize"},
  "BJ": {nome: "Benin"},
  "BM": {nome: "Bermuda"},
  "BY": {nome: "Bielorussia"},
  "BT": {nome: "Bhutan"},
  "BO": {nome: "Bolivia"},
  "BA": {nome: "Bosnia Erzegovina"},
  "BW": {nome: "Botswana"},
  "BR": {nome: "Brasile"},
  "BN": {nome: "Brunei Darussalam"},
  "BF": {nome: "Burkina Faso"},
  "BI": {nome: "Burundi"},
  "KH": {nome: "Cambogia"},
  "CM": {nome: "Camerun"},
  "CA": {nome: "Canada"},
  "CV": {nome: "Capo Verde"},
  "TD": {nome: "Ciad"},
  "CL": {nome: "Cile"},
  "CN": {nome: "Cina"},
  "VA": {nome: "Citta del Vaticano"},
  "CO": {nome: "Colombia"},
  "KM": {nome: "Comore"},
  "KP": {nome: "Corea del Nord"},
  "KR": {nome: "Corea del Sud"},
  "CR": {nome: "Costa Rica"},
  "CI": {nome: "Costa d'Avorio"},
  "HR": {nome: "Croazia"},
  "CU": {nome: "Cuba"},
  "DM": {nome: "Dominica"},
  "EC": {nome: "Ecuador"},
  "EG": {nome: "Egitto"},
  "SV": {nome: "El Salvador"},
  "AE": {nome: "Emirati Arabi Uniti"},
  "ER": {nome: "Eritrea"},
  "ET": {nome: "Etiopia"},
  "RU": {nome: "Federazione Russa"},
  "FJ": {nome: "Fiji"},
  "PH": {nome: "Filippine"},
  "GA": {nome: "Gabon"},
  "GM": {nome: "Gambia"},
  "GE": {nome: "Georgia"},
  "GH": {nome: "Ghana"},
  "JM": {nome: "Giamaica"},
  "JP": {nome: "Giappone"},
  "GI": {nome: "Gibilterra"},
  "DJ": {nome: "Gibuti"},
  "JO": {nome: "Giordania"},
  "GD": {nome: "Grenada"},
  "GL": {nome: "Groenlandia"},
  "GP": {nome: "Guadalupa"},
  "GU": {nome: "Guam"},
  "GT": {nome: "Guatemala"},
  "GG": {nome: "Guernsey"},
  "GN": {nome: "Guinea"},
  "GW": {nome: "Guinea-Bissau"},
  "GQ": {nome: "Guinea Equatoriale"},
  "GY": {nome: "Guyana"},
  "GF": {nome: "Guyana Francese"},
  "HT": {nome: "Haiti"},
  "HN": {nome: "Honduras"},
  "HK": {nome: "Hong Kong"},
  "IN": {nome: "India"},
  "ID": {nome: "Indonesia"},
  "IR": {nome: "Iran"},
  "IQ": {nome: "Iraq"},
  "BV": {nome: "Isola di Bouvet"},
  "CX": {nome: "Isola di Natale"},
  "HM": {nome: "Isola Heard e Isole McDonald"},
  "KY": {nome: "Isole Cayman"},
  "CC": {nome: "Isole Cocos"},
  "CK": {nome: "Isole Cook"},
  "FK": {nome: "Isole Falkland"},
  "FO": {nome: "Isole Faroe"},
  "MH": {nome: "Isole Marshall"},
  "MP": {nome: "Isole Marianne Settentrionali"},
  "UM": {nome: "Isole Minori degli Stati Uniti d'America"},
  "NF": {nome: "Isola Norfolk"},
  "SB": {nome: "Isole Solomon"},
  "TC": {nome: "Isole Turks e Caicos"},
  "VI": {nome: "Isole Vergini Americane"},
  "VG": {nome: "Isole Vergini Britanniche"},
  "IL": {nome: "Israele"},
  "IS": {nome: "Islanda"},
  "KZ": {nome: "Kazakhistan"},
  "KE": {nome: "Kenya"},
  "KG": {nome: "Kirghizistan"},
  "KI": {nome: "Kiribati"},
  "KW": {nome: "Kuwait"},
  "LA": {nome: "Laos"},
  "LS": {nome: "Lesotho"},
  "LB": {nome: "Libano"},
  "LR": {nome: "Liberia"},
  "LY": {nome: "Libia"},
  "LI": {nome: "Liechtenstein"},
  "MO": {nome: "Macao"},
  "MG": {nome: "Madagascar"},
  "MW": {nome: "Malawi"},
  "MV": {nome: "Maldive"},
  "MY": {nome: "Malesia"},
  "ML": {nome: "Mali"},
  "MA": {nome: "Marocco"},
  "MQ": {nome: "Martinica"},
  "MR": {nome: "Mauritania"},
  "MU": {nome: "Maurizius"},
  "YT": {nome: "Mayotte"},
  "MX": {nome: "Messico"},
  "MD": {nome: "Moldavia"},
  "MC": {nome: "Monaco"},
  "MN": {nome: "Mongolia"},
  "MS": {nome: "Montserrat"},
  "MZ": {nome: "Mozambico"},
  "MM": {nome: "Myanmar"},
  "NA": {nome: "Namibia"},
  "NR": {nome: "Nauru"},
  "NP": {nome: "Nepal"},
  "NI": {nome: "Nicaragua"},
  "NE": {nome: "Niger"},
  "NG": {nome: "Nigeria"},
  "NU": {nome: "Niue"},
  "NO": {nome: "Norvegia"},
  "NC": {nome: "Nuova Caledonia"},
  "NZ": {nome: "Nuova Zelanda"},
  "OM": {nome: "Oman"},
  "PK": {nome: "Pakistan"},
  "PW": {nome: "Palau"},
  "PA": {nome: "Panama"},
  "PG": {nome: "Papua Nuova Guinea"},
  "PY": {nome: "Paraguay"},
  "PE": {nome: "Peru"},
  "PN": {nome: "Pitcairn"},
  "PF": {nome: "Polinesia Francese"},
  "PR": {nome: "Porto Rico"},
  "QA": {nome: "Qatar"},
  "CF": {nome: "Repubblica Centroafricana"},
  "CG": {nome: "Repubblica del Congo"},
  "CD": {nome: "Repubblica Democratica del Congo"},
  "DO": {nome: "Repubblica Dominicana"},
  "RE": {nome: "Reunion"},
  "RW": {nome: "Ruanda"},
  "EH": {nome: "Sahara Occidentale"},
  "KN": {nome: "Saint Kitts e Nevis"},
  "PM": {nome: "Saint Pierre e Miquelon"},
  "VC": {nome: "Saint Vincent e Grenadine"},
  "WS": {nome: "Samoa"},
  "AS": {nome: "Samoa Americane"},
  "SM": {nome: "San Marino"},
  "SH": {nome: "Sant'Elena"},
  "LC": {nome: "Santa Lucia"},
  "ST": {nome: "Sao Tome e Principe"},
  "SN": {nome: "Senegal"},
  "XK": {nome: "Kosovo"},
  "SC": {nome: "Seychelles"},
  "SL": {nome: "Sierra Leone"},
  "SG": {nome: "Singapore"},
  "SY": {nome: "Siria"},
  "SO": {nome: "Somalia"},
  "LK": {nome: "Sri Lanka"},
  "FM": {nome: "Stati Federati della Micronesia"},
  "US": {nome: "Stati Uniti d'America"},
  "ZA": {nome: "Sud Africa"},
  "GS": {nome: "Sud Georgia e Isole Sandwich"},
  "SD": {nome: "Sudan"},
  "SR": {nome: "Suriname"},
  "SJ": {nome: "Svalbard e Jan Mayen"},
  "CH": {nome: "Svizzera"},
  "SZ": {nome: "Swaziland"},
  "TJ": {nome: "Tagikistan"},
  "TH": {nome: "Tailandia"},
  "TW": {nome: "Taiwan"},
  "TZ": {nome: "Tanzania"},
  "IO": {nome: "Territori Britannici dell'Oceano Indiano"},
  "TF": {nome: "Territori Francesi del Sud"},
  "PS": {nome: "Territori Palestinesi Occupati"},
  "TL": {nome: "Timor Est"},
  "TG": {nome: "Togo"},
  "TK": {nome: "Tokelau"},
  "TO": {nome: "Tonga"},
  "TT": {nome: "Trinidad e Tobago"},
  "TN": {nome: "Tunisia"},
  "TR": {nome: "Turchia"},
  "TM": {nome: "Turkmenistan"},
  "TV": {nome: "Tuvalu"},
  "UA": {nome: "Ucraina"},
  "UG": {nome: "Uganda"},
  "UY": {nome: "Uruguay"},
  "UZ": {nome: "Uzbekistan"},
  "VU": {nome: "Vanuatu"},
  "VE": {nome: "Venezuela"},
  "VN": {nome: "Vietnam"},
  "WF": {nome: "Wallis e Futuna"},
  "YE": {nome: "Yemen"},
  "ZM": {nome: "Zambia"},
  "ZW": {nome: "Zimbabwe"},
  "RS": {nome: "Serbia"},
  "ME": {nome: "Montenegro"},
  "TP": {nome: "Timor Est"},
  
  getEnum(){
    return Object.entries(this).filter(([_,v]) => typeof v === "object").map(([k,{nome}]) => ({ "value": k, "label": nome}))
  }
})

export default NazioniIndirizzo